.empty-content {

    height: 400px;
    width: 80%;
    margin: auto;

    // border: 1px solid #DDD;
    border-radius: 4px;


}