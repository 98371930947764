.tab-container {
    width: 100%;
    display: flex;
    
    ul {
        margin: 0 auto;
        display: flex;
        width: 60%;
        height: 100%;
        justify-content: center;

        li {
            margin: 0 10px;
            display: flex;
            flex: 1;
            align-items: center;    
            border-bottom: 2px solid transparent;
            justify-content: center;
            font-family: Rubik-Light;
            font-size: 14px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: rgba(0, 0, 0, 0.87);
            min-height: 40px;
            cursor: pointer;
           
            &.active {
                color: #d63031;            
                border-bottom: 2px solid #d63031;
            }

            &:hover {
                color: #d63031;
            }

            img {
                width: 12px;
                height: 12px;
                margin-right: 8px;
            }
        }
    }
}