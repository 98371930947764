.main-header-container {
    display: block;
    @media only screen and (max-width: 750px) {
        height: 50px;
    }
}

.main-header {
    width: 100%;
    padding: 0 5%;
    max-width: 1440px;
    height: 72px;
    position: fixed;
    top: 0;
    z-index: 99999;
    display: flex;
    justify-content: space-between;
    background-color: #000000;
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.12);

    @media only screen and (max-width: 1200px) {
        padding: 0 10px;
    }

    @media only screen and (max-width: 750px) {
        height: 50px;
        position: fixed;
        z-index: 9999;
        top: 0;
    }

    .button {
        @media only screen and (max-width: 750px) {
            padding: 5px;
        }
    }
        
    .app-logo {
        width: 250px;
        height: 100%;
        margin-right: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        img {
            display: inline-block;
            padding: 3px;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
        
        @media only screen and (max-width: 750px) {
            width: 125px;
            margin-right: 0;

            img {
                width: 125px;
                height: 48px;
                margin-top: 0;
                object-fit: contain;    
            }
        }
    
    }

    .header-menu-app {
        justify-content: center;
        align-items: center;
        display: flex;
        padding: 10px;
        font-family: Rubik-Light;
        font-size: 16px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: rgba(0, 0, 0, 0.87);
        text-decoration: none !important;

        &.active {
            color: #d63031;
            background: #f4f4f4;
        }

        img {
            cursor: pointer;
        }
    }

    .menu-icon {
        font-family: Rubik-Light;
        font-size: 16px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: rgba(0, 0, 0, 0.87);
        text-decoration: none !important;
    }

    .desktop-only {
        display: flex;
        height: calc(100% - 4px);

        @media only screen and (max-width: 750px) {
            display: none;
        }
    }

    .mobile-only {
        display: none;
        text-align: left;

        .menu-icon {
            padding: 15px 10px;
            cursor: pointer;
            float: left;
            
            img {
                width: 20px;
                height: 20px;
            }
        }

        ul {
            position: absolute;
            background: #ffffff;
            z-index: 1000;
            width: 100%;
            left: 0;
            top: 50px;
            transition: 1.0s;
            border: 1px solid #DDD;

            li {
                padding: 10px;
                font-family: Rubik-Light;
                font-size: 16px;
                font-weight: 600;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: rgba(0, 0, 0, 0.87);
                text-decoration: none !important;

                &.active {
                    color: #d63031;
                    background: #f4f4f4;
                }
            }

        }

        @media only screen and (max-width: 750px) {
            display: block;
        }
    }

    .header-menu {

        @media only screen and (max-width: 750px) {
            display: none;
        }

        ul {
            display: flex;
            height: 100%;
            justify-content: center;
    
            li {
                margin: 0 10px;
                display: flex;
                width: 110px;
                align-items: center;    
                border-bottom: 4px solid transparent;
                justify-content: center;
                font-family: Rubik;
                font-size: 14px;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: rgba(255, 255, 255, 0.6);
                text-decoration: none !important;
                cursor: pointer;

                @media only screen and (max-width: 960px) {
                    font-size: 13px;
                    width: unset;
                }
            
                &:hover {
                    color: #ffffff;
                }
            
                &.active {
                    color: #ffffff;
                    border-bottom: 4px solid #d63031;
                }

                &.mobile {
                    display: none;
    
                    @media only screen and (max-width: 750px) {
                        display: block;
                    }
                }
            }
        }
    }

    .host-panel {
        background: #272727;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 5px;

        @media only screen and (max-width: 750px) {
            padding: 5px;
        }

        .host-button {
            margin-right: 10px;
        }

        .login-button {
            background: transparent;
            border-radius: 4px;
            border: 1px solid #a71e22;
            min-width: 80px;
        }

        .user-info {
            display: flex;
            justify-content: center;
            align-items: center;
            color: #EEE;
            cursor: pointer;

            img {
                width: 40px;
                height: 40px;
                margin: 0 10px;
                border-radius: 20px;
                background: white;
            }

            div {
                margin-left: 10px;
            }

            .user-menu {
                position: absolute;
                background: #F0F0F0;
                border: 1px solid #ddd;
                border-radius: 4px;
                top: 10px;
                width: 200px;
                margin-left: 74px;
                color: #333;
                font-family: 'Roboto';
                text-align: left;
                
                @media only screen and (max-width: 750px) {
                    top: 2px;
                    right: 10px;
                }

                img {
                    margin: 0;
                    margin-right: 10px;
                    border: 2px solid #a71e22;
                }

                .user-profile {
                    padding: 5px;
                    font-size: 14px;
                    font-weight: bold;
                    margin: 0;
                }

                li {
                    display: block;
                    padding: 5px;
                    border-top: 1px solid #DDD;
                    background: white;
                    span {
                        margin-right: 5px;
                    }

                    &:hover {
                        background: #be2c2720;
                    }
                }
            }
        }
    }

    .facebook {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 20px;

        @media only screen and (max-width: 750px) {
            display: none;
        }

        .text {
            font-size: 12px;
            width: 120px;
            line-height: 15px;
            text-align: right;
            margin-right: 10px;
            color: #CCC;
        }

        .fb-like-button {
            background: #365899;
            border-color: #365899;
            font-size: 12px;
            padding: 2px 5px;
        }
    }
  
    .header-right-menu {
        width: 170px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0 10px;
      
        .menu-icon {
            padding: 3px;
            margin: 15px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }

        ul {
            margin: 0 auto;
            justify-content: center;
            position: absolute;
            top: 65px;
            right: 20px;
            z-index: 100;
            background: white;
            border: 1px solid #dddddd;

            @media only screen and (max-width: 750px) {
                top: 40px;
            }

            li {
                margin: 0 10px;
                display: flex;
                flex: 1;
                align-items: center;    
                border-bottom: 4px solid transparent;
                justify-content: left;
                font-family: Rubik-Light;
                font-size: 16px;
                font-weight: 600;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: rgba(0, 0, 0, 0.87);
                text-decoration: none !important;
                cursor: pointer;
                padding: 10px;

                @media only screen and (max-width: 750px) {
                    font-size: 13px;
                    font-weight: 600;
                    padding: 5px;
                    text-align: left;
                }            
            
                &:hover {
                    color: #d63031;
                }
            
                &.active {
                    color: #d63031;            
                    border-bottom: 4px solid #d63031;
                }
            }
        }

    }
}