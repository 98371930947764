.input-form {
    display: flex;
    margin: 10px 0px;
    justify-content: center;
    align-items: center;
    
    .form-control {
        border-radius: 4px;
        border-color: #CCC;
        &:focus {
            border-color: rgb(143, 96, 96);
        }
    }
}