.ads-slider {
    width: 100%;
    height: 100%;
    position: relative;

    .rcs_sliderSet, .rcs_sliderBox {
        width: 100% !important;
        height: 100% !important;
    }

    .rcs_slideCon .itemWrapper {
        width: 100%;
        height: auto;
        margin: 0 !important;
    }

    .banner-loader {
        padding: 30px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 500px;
        background: #828282a1;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}