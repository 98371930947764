.event-content-container {
    position: relative;
    font-family: 'Roboto';

    .panel {
        border-radius: 4px;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
        background-color: #ffffff;
        border: solid 1px #dbdbdb;
        padding: 20px;
        overflow: hidden;
    }

    .row {
        display: flex;
        margin: 0;
    }

    .flex-center {
        justify-content: flex-start;
        align-items: center;
    }

    .event-content {
        margin: auto;
        width: 80%;;
            
        @media only screen and (max-width: 750px) {
            width: 96%;
        }

        .main-title {
            padding-top: 50px;
            font-size: 28px;
            font-weight: bold;
            text-align: left;
            color: #222;
            font-family: 'Roboto';

            @media only screen and (max-width: 750px) {
                padding-top: 10px;
            }
        }

        .event-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin: 50px 0;
            
            @media only screen and (max-width: 750px) {
                margin: 0;
            }

            .event-item {
                width: 30%;
                margin-right: 1%;
                height: auto;
                position: relative;
                margin-bottom: 40px;
                border-radius: 8px;
                overflow: hidden;
            
                @media only screen and (max-width: 750px) {
                    width: 90%;
                    margin-left: 5%;
                    margin-bottom: 20px;
                }
    
                .image-panel {
                    width: 100%;
                    height: 100%;
                    display: flex;

                    img {
                        width: 100%;
                        height: 100%;
                        flex: 1;
                        object-fit: cover;
                    }                        
                }

                .action-pane {
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: 9999;
                    padding: 10px 20px 20px 20px;
                    background: rgba(0, 0, 0, 0.9);
                    color: white;
                    cursor: pointer;

                    .event-title {
                        font-size: 18px;
                        text-align: left;
                        height: 30px;
                        line-height: 23px;
                        color: white;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;

                        @media only screen and (max-width: 750px) {
                            font-size: 16px;
                        }
                    }

                    .event-price {
                        background: white;
                        border-radius: 4px;
                        color: #eb2732;
                        font-weight: bold;
                        font-size: 11px;
                        height: 18px;
                        padding: 0 10px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-right: 10px;
                    }

                    .event-time {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        span {
                            font-size: 4px;
                            margin: 0 5px;
                        }
                    }

                    .book-button {
                        display: none;
                        margin-top: 10px;
                        font-weight: 500;
                        font-size: 15px;

                        @media only screen and (max-width: 750px) {
                            display: block;
                        }
                    }

                    &:hover {
                        .book-button {
                            display: block;
                        }
                    }
                }
            }
        }
    }

}