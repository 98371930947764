.coupon-container {
    padding: 20px;
    font-family: 'Open Sans';
    
    .loading-container {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 9999;
        background: rgba(255, 255, 255, 0.7);
        padding: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .module {
        margin: auto;
        width: 80%;
        @media only screen and (max-width: 750px) {
            width: 100%;
        }

        .head-title {
            padding-top: 40px;
            font-size: 24px;
            text-align: left;
            padding-bottom: 10px;
            font-weight: bold;
        }
    }

    .dash-coupon .control-label {
        min-width: 200px;
        margin-right: 20px;
    }

    .dash-coupon .small {
        min-width: 0px;
        margin-left: 10px;
        margin-right: 5px;
    }

    .input-paragraph {
        padding: 10px 0;
        border-bottom: 1px solid #E0E0E0;
    }

    .dash-coupon .controls {
        flex: 2;
    }

    .dash-coupon .help-inline {
        flex: 1;
        text-align: left;
        margin-left: 20px;
    }

    th {
        text-align: center;
    }

    .form-horizontal {
        border: 1px solid #DDD;
        padding: 5px;
    }

    .active-value {
        cursor: pointer;
        color: blue;

        &:hover {
            color: red;
        }
    }

    .input-form, .input-paragraph {
        .react-date-picker {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .react-date-picker__wrapper {
            border-radius: 5px;
        }
    }
}