.terms-content-container {
    font-family: 'Open Sans';
    color: #000;

    .terms-content {
        width: 80%;
        margin: auto;
        display: flex;
        flex-direction: column;
        text-align: center;

        @media only screen and (max-width: 750px) {
            width: 90%;
        }

        .panel-title {
            font-family: 'Roboto';
            font-size: 30px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            padding: 20px 0 10px 0;
            padding-top: 100px;
            text-align: left;

            @media only screen and (max-width: 600px) {
                font-size: 20px;
                padding: 15px 0;
                padding-top: 20px;
            }
        }

        .panel-content {
            margin-top: 20px;
            font-size: 16px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            margin: 20px auto;
            max-height: 1200px;
            overflow-y: scroll;
            text-align: left;
            display: flex;
            flex-direction: column;
            border: 1px solid #CCC;
            border-radius: 3px;
            padding: 5px;

            @media only screen and (max-width: 750px) {
                margin: 0 auto !important;
                max-width: 100% !important;
            }

            .panel-description {
                margin-bottom: 20px;
                font-weight: normal;
                font-family: 'Roboto';

                p {
                    font-size: 16px;

                    strong {
                        font-size: 20px;
                        font-weight: bold;
                    }
                }

                h2 {
                    margin: 0;
                    margin-top: 30px;
                    margin-bottom: 15px;
                    font-size: 17px;
                    font-family: 'Open Sans';
                    font-weight: bold;
                }
            }

            input,
            textarea {
                display: block;
                margin: 10px auto;
                width: 100%;
                max-width: 400px;
                padding: 8px;
                border-radius: 4px;
                border: 1px solid #DDD;
                font-size: 14px;
            }

            @media only screen and (max-width: 600px) {
                font-size: 14px;
                font-weight: bold;
                padding: 10px;

                .panel-description {
                    margin-bottom: 10px;
                }

                input,
                textarea {
                    width: 100%;
                }
            }

        }

        .button {
            font-weight: normal;
            font-size: 14px;
            padding: 10px;
            margin-top: 20px;
        }

        .terms-info {
            display: flex;
            margin-top: 50px;
            margin-bottom: 20px;
            justify-content: space-around;
            align-items: center;

            .terms-info-item {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                font-family: Rubik-Light;
                font-size: 20px;
                font-weight: bold;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;

                img {
                    height: 50px;
                    margin-bottom: 20px;
                    display: block;
                }
            }

            @media only screen and (max-width: 600px) {
                margin-top: 10px;
                margin-bottom: 10px;

                .terms-info-item {
                    font-size: 16px;

                    img {
                        height: 30px;
                        margin-bottom: 10px;
                        display: block;
                    }
                }
            }
        }
    }
}