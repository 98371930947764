.ticket-detail-container {
    text-align: center;
    min-height: 500px;
    padding: 50px;
    font-family: 'Roboto';
    background: rgba(241, 241, 234, 0.867);

    @media only screen and (max-width: 700px) {
        padding: 0;
    }

    .loading-view {
        font-size: 20px;
        color: #a71e22;
    }

    .ticket-container {
        width: 60%;
        margin: auto;

        @media only screen and (max-width: 700px) {
            width: 96%;
        }
    }
}