.checkout-content-container {
    position: relative;
    font-family: 'Open Sans';
    scroll-behavior: smooth;

    .panel {
        border-radius: 4px;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
        background-color: #ffffff;
        border: solid 1px #dbdbdb;
        padding: 20px;
        overflow: hidden;
    }

    .panel-title {
        font-family: 'Roboto';
        font-size: 28px;
        text-align: left;
        font-weight: bold;
        color: #333;
        padding: 10px;
        padding-left: 20px;

        @media only screen and (max-width: 750px) {
            font-size: 18px;
            padding: 10px;
        }
    }

    .row {
        display: flex;
        margin: 0;
    }

    .flex-full {
        flex: 1;
    }

    .flex-center {
        justify-content: flex-start;
        align-items: center;
    }

    .checkout-header {
        display: flex;
        padding: 0;
        margin: auto;
        z-index: 9999;
        width: 80%;
        margin: auto;
        padding-top: 50px;
        font-size: 16px;
        
        @media only screen and (max-width: 750px) {
            width: 96%;
            padding-top: 20px;
            font-size: 13px;
        }

        .path {
            display: flex;
            @media only screen and (max-width: 750px) {
                text-align: left;
                display: block;
            }
            flex: 1;
            flex-wrap: wrap;
        }

        img {
            width: 24px;
            height: 24px;
            margin-right: 10px;
        }

        .active {
            color: #a71e22;
            border-bottom: 3px solid #a71e22;
        }

        a:hover {
            color: #a71e22;
        }
    }

    .loading-container {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 9999;
        background: rgba(255, 255, 255, 0.7);
        padding: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .loading-view {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 999;
        background: rgba(255, 255, 255, 0.9);
        padding: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        font-size: 14px;

        @media only screen and (max-width: 750px) {
            padding: 10px;
        }

        i {
            color: #a71e22;
            margin-top: 10px;
        }

        .stripe-logo {
            font-size: 30px;
            color: #6365E5;
            font-weight: bold;
            font-family: 'Arial';
        }
    }

    .card {
        padding: 5px;
        margin: 5px;
        cursor: pointer;
        border: 1px solid #dbdbdb;
        border-radius: 4px;

        .card-added {
            display: flex;
            align-items: center;

            .select {
                width: 16px;
                height: 16px;
                border-radius: 8px;
                border: 4px solid #ea2027;
                margin-right: 10px;
            }

            .active {
                background: #ea2027;
            }

            .card-number {
                font-family: Rubik-Medium;
                font-size: 14px;
                font-weight: bold;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #282828;
            }
        }

        .card-type {
            font-family: Rubik-Medium;
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #282828;
            margin-left: 26px;
        }
    }

    .checkout-content {
        margin: auto;
        width: 80%;
        padding: 60px 0;
        display: flex;

        @media only screen and (max-width: 750px) {
            width: 96%;
            padding-top: 20px;
            display: block;
        }

        input[type=date]::-webkit-inner-spin-button {
            -webkit-appearance: none;
            display: none;
        }

        input, textarea {
            flex: 1;
            min-width: 0;
            width: 100%;
            border-radius: 4px;
            padding: 5px;
            border: 1px solid #CCC;
        }

        textarea {
            resize: none;
            height: 100px;
        }

        .payment-method {
            width: calc(75% - 20px);
            border: 1px solid #DDD;
            border-radius: 4px;
            background: #F9F9F9;
            text-align: left;
            display: flex;
            flex-direction: column;

            @media only screen and (max-width: 750px) {
                width: 100%;
                display: block;
            }

            .method-content {
                border-top: 1px solid #DDD;
                display: flex;
                color: #222;
                position: relative;
                
                @media only screen and (max-width: 750px) {
                    display: block;
                }
            }

            input {
                margin: 5px;
                // padding: 0 5px;
            }
    
            .card-info {
                font-size: 16px;
                border-left: 1px solid #DDD;
                background: white;

                .block-title {
                    font-size: 14px;
                }
                
                @media only screen and (max-width: 750px) {
                    border-left: none;
                    border-bottom: none;
                    font-size: 12px;
                }

                .radio-group {
                    div {
                        border: none !important;
                        padding: 0 !important;
                    }
                }

                .card-name {
                    flex: 1;
                }

                .card-detail {
                    flex: 1;
                    display: flex;

                    input {
                        text-align: center;
                    }
                }

                .error {
                    margin-top: 10px;
                    background: #FFDeDe;
                    color: #a71e22;
                    font-size: 12px;
                    font-weight: bold;
                    border-radius: 4px;
                    padding: 5px 10px;
                    line-height: 15px;
                }
            }

            .content-panel {
                flex: 1;
                padding: 20px;

                @media only screen and (max-width: 750px) {
                    padding: 5px;
                    border-bottom: 1px solid #DDD;
                }

                .button {
                    font-weight: bold;
                    font-size: 14px;
                    text-align: center;
                    width: 140px;
                }

                .block {
                    border-bottom: 1px solid #DDD;
                    font-size: 16px;
                    padding-bottom: 15px;

                    // @media only screen and (max-width: 750px) {
                    //     padding-bottom: 5px;
                    // }
                }

                .block-title {
                    color: #a71e22;
                    font-weight: bold;
                    font-size: 16px;
                    padding: 10px 0;

                    @media only screen and (max-width: 750px) {
                        font-size: 14px;
                        // padding-bottom: 5px;
                        line-height: 20px;
                    }
                }

                .block-item {
                    display: flex;
                    font-size: 16px;

                    @media only screen and (max-width: 750px) {
                        font-size: 14px;
                    }

                    .item-left {
                        flex: 1;
                        display: flex;
                        align-items: center;

                        span {
                            font-size: 6px;
                            margin-left: 10px;
                            margin-right: 5px;
                        }
                    }

                    .item-right {
                        font-size: 18px;
                        font-weight: bold;
                        
                        @media only screen and (max-width: 750px) {
                            font-size: 14px;
                        }
                    }
                }
            }
        }

        .payment-summary {
            margin-left: 20px;
            border: 1px solid #DDD;
            border-radius: 4px;
            flex: 1;
            background: #F9F9F9;
            text-align: left;
            font-size: 16px;
            color: #222;

            .panel-title {
                @media only screen and (max-width: 750px) {
                    border-bottom: 1px solid #DDD;
                }
            }

            @media only screen and (max-width: 750px) {
                margin-left: 0px;
                margin-top: 10px;
                font-size: 13px;
                margin-bottom: 10px;
            }

            input {
                margin-right: 10px;
                height: 36px;
            }

            .button {
                font-size: 12px;
                font-weight: bold;
            }
    
            .block {
                border-bottom: 1px solid #DDD;
                font-size: 16px;
                padding: 5px 0;
                @media only screen and (max-width: 750px) {
                    font-size: 13px;
                }
            }

            .content-panel {
                flex: 1;
                margin: 0 20px;
                font-size: 16px;

                @media only screen and (max-width: 750px) {
                    margin: 0 10px;
                    padding: 10px 0;
                }

                .title {
                    display: flex;
                    align-items: center;
                    font-weight: bold;
                    font-size: 16px;

                    span {
                        color: #44bd32;
                        font-size: 20px;
                        margin-right: 10px;
                    }
                }

                .item {
                    display: flex;
                    font-size: 16px;
                    font-weight: bold;
                    color: #44bd32;
                }
            }
        }
    }
}

.phone-input-container {
    margin: 5px;
    font-size: 16px !important;
    width: calc(100% - 15px) !important;
    height: 47px !important;
}

.phone-input {
    margin: 5px !important;
    height: auto !important;
    font-size: 16px !important;
    padding-left: 40px !important;
    height: 47px !important;
}