.rcs_sliderBox {
    width: 100% !important;
    height: 534px !important;
}

/* .rcs_slideCon {
    height: 95% !important;
} */

.hover-item {
    width: 150px !important;
    top: 10% !important;
    color: #ffffff !important;
    margin-right: 3px !important;
    cursor: pointer !important;
}

.hover-item:hover {
    text-decoration: underline;
}

.banner-loader {
    padding: 30px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 500px;
    background: #828282a1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.banner-container {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;

    .banner-content {
        width: 100%;
        // width: calc(100% + 160px);
        // margin-left: -80px;
        height: 100%;
        position: relative;    
    }
}