.backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 50px;
  z-index: 1000;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.2s linear;
}

.modal-dialog-view {
  background-color: #fff;
  border-radius: 5px;
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
  padding: 30px;
  position: relative;
}

.visible {
  visibility: visible;
  opacity: 1;
}

.close-button {
  width: 30px;
  height: 30px;
  text-align: right;
  position: absolute;
  top: -15px;
  right: -15px;
  cursor: pointer;
  border-radius: 15px;
  background: #595959;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.close-button:hover {
  color: #fa755a;
}

@media only screen and (max-width: 600px) {
  .backdrop {
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .close-button {
    display: none;
  }

  .modal-dialog-view {
    padding: 0;
  }
}