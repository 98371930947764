.book-detail-container {
    text-align: left;
    min-height: 500px;
    padding: 50px;
    font-family: 'Roboto';

    @media only screen and (max-width: 700px) {
        padding: 10px;
    }

    .loading-container {
        position: fixed !important;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 9999;
        background: rgba(255, 255, 255, 0.7);
        padding: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .order-container {
        padding: 20px;
        width: 60%;
        margin: 40px auto;
        border: 1px solid #CCC;
        color: #888;
        position: relative;

        .print-icon {
            position: absolute;
            top: 10px;
            right: 10px;
            width: 30px;
            height: 30px;
            cursor: pointer;
        }

        .confirm-icon {
            margin-top: 20px;
            width: 60px;
        }

        .confirm-title {
            margin-top: 10px;
            font-size: 24px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
        }

        .confirm-event {
            color: #AAA;
            margin-top: 10px;
            font-size: 20px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
        }

        .confirm-scan-title {
            margin: 20px 0 10px 0;
            font-size: 24px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
        }

        .confirm-detail {

            table {
                border-collapse: collapse;
                margin: 10px auto;
            }

            td {
                border: 1px solid transparent;
                padding: 10px;
            }

            .title {
                margin-top: 20px;
                font-size: 24px;
                font-weight: 600;
                
            }

            .detail-item {
                margin: 10px auto;
                align-items: center;
                justify-content: left;

                .detail-title {
                    font-size: 16px;
                    font-weight: normal;
                    margin-right: 10px;
                    text-align: left;
                }

                .detail-description {
                    text-align: left;
                }
            }

            font-size: 16px;
            font-weight: bold;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
        }

        @media only screen and (max-width: 700px) {
            margin: 10px;
            width: calc(100% - 20px);
            padding: 10px;
            position: relative;

            .confirm-icon {
                margin-top: 10px;
                width: 30px;
            }

            .confirm-title {
                font-size: 18px;
            }

            .confirm-event {
                font-size: 16px;
            }

            .confirm-scan-title {
                font-size: 16px;
            }

            .confirm-detail {
                
                .title {
                    font-size: 16px;
                }

                .detail-item {
                    font-size: 16px;
                    width: 100%;
                }
            }
        }
    }

    
    .row {
        display: flex;
        margin: 0 auto;
        align-items: flex-start;

        @media only screen and (max-width: 750px) {
            display: block;
        }
    }

    .book-confirm-content {
        background: white;
        width: 60%;
        margin: 20px auto;
        border: 1px solid #DDD;

        @media only screen and (max-width: 750px) {
            width: 96%;
        }
    }

    .block {
        border-bottom: 1px solid #DDD;
        padding: 20px;

        @media only screen and (max-width: 750px) {
            padding: 10px;
        }

        img {
            margin-right: 10px;
            width: 50px;
            height: 50px;

            @media only screen and (max-width: 750px) {
                display: none;
            }
        }

        img.img-btn {
            cursor: pointer;
            @media only screen and (max-width: 750px) {
                display: block;
            }

            &:hover {
                border: 2px solid white;
            }
        }
    }

    .small-height {
        height: 24px;
    }

    .medium-height {
        height: 30px;
    }

    .border-top {
        border-top: 1px solid #EEE;
    }

    .border-bottom {
        border-bottom: 1px solid #EEE;
    }

    .padding-top-small {
        padding-top: 5px;
    }

    .padding-bottom-small {
        padding-bottom: 5px;
    }

    .block-title {
        font-weight: bold;
        font-size: 16px;
        color: #333;
        margin-bottom: 10px;

        @media only screen and (max-width: 750px) {
            font-size: 14px;
            line-height: 20px;
        }

    }

    .block-detail {
        font-size: 12px;
        font-weight: bold;
        color: #888;
        line-height: 16px;
    }

    .red {
        color: #a71e22;
    }

    .block-item {
        font-size: 14px;
        font-weight: bold;

        .row {
            padding: 10px 0;
            align-items: center;
        }

        .btn-row {
            display: flex;
            @media only screen and (max-width: 750px) {
                margin-left: calc(100% - 230px);
                margin-top: 10px;
            }
        }
        .email-input {
            margin-right: 20px;
            border: 1px solid #CCC;
            border-radius: 4px;
            padding: 0 5px;
            width: 100% !important;
            margin-bottom: 10px;
            
            @media only screen and (max-width: 750px) {
                margin-right: 0;
                width: 100%;
            }
        }
    }

    .button {
        width: 100px;
        text-align: center;
    }
}