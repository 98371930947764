.dropdown-container {
    width: 100%;
    background: white;
    border-radius: 4px;
    border: 1px solid #dbdbdb;
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    position: relative;

    @media only screen and (max-width: 600px) {
        font-size: 12px;
    }

    .caption {
        display: flex;
        align-items: center;
        padding: 14px;
        cursor: pointer;

        @media only screen and (max-width: 600px) {
            padding: 5px;
        }

        .title {
            flex: 1;
        }
    }

    ul {
        margin: 0;
        width: 100%;
        background: white;
        border-radius: 4px;
        border: 1px solid #dbdbdb;
        position: absolute;
        width: 100%;
        z-index: 1000;

        li {
            padding: 14px;
            cursor: pointer;

            @media only screen and (max-width: 600px) {
                padding: 5px;
                font-size: 12px;
            }
           
            &:hover {
                background-color: #eaeced;
            }
        }
    }
}