.about-content-container {
    position: relative;
    font-family: 'Roboto';

    .panel {
        border-radius: 4px;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
        background-color: #ffffff;
        border: solid 1px #dbdbdb;
        padding: 20px;
        overflow: hidden;
    }

    .row {
        display: flex;
    }

    .flex-center {
        justify-content: flex-start;
        align-items: center;
    }

    .about-content {
        margin: auto;
        width: 80%;

        .carousel {
            width: 100%;
            height: 400px;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }

        .page-image {
            flex-shrink: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .event-title {
            font-size: 30px;
            font-weight: bold;
            color: black;
            text-align: left;
            margin-left: 10%;
            line-height: 40px;
            margin-bottom: 30px;
            font-family: 'Roboto';

            @media only screen and (max-width: 750px) {
                font-size: 24px;
                margin-left: 20px;
                margin-bottom: 10px;
            }
        }

        .about {
            width: 100%;
            text-align: left;
            margin: auto;

            @media only screen and (max-width: 750px) {
                width: 100%;                
            }

            .about-title {
                font-size: 30px;
                font-weight: bold;
                color: black;
                text-align: left;
                line-height: 40px;
                padding-top: 100px;
                margin-bottom: 30px;

                @media only screen and (max-width: 750px) {
                    padding-top: 20px;
                    font-size: 20px;
                    max-width: unset;
                    line-height: 1.7;
                    margin-bottom: 5px;
                }
            }

            .about-description {
                font-size: 16px;
                margin-bottom: 50px;
            }
        }

        .host-event-panel {
            margin: auto;
            margin-top: 100px;
            background: #f9f9f9;
            display: flex;
            padding: 50px 10%;

            @media only screen and (max-width: 750px) {
                margin-top: 50px;
                padding: 10px;
                display: block;
            }

            .event-title {
                margin: 0;
            }

            .ctrl-panel {
                display: flex;
                justify-content: center;
                align-items: center;

                @media only screen and (max-width: 750px) {
                    display: block;
                    text-align: center;
                    margin-bottom: 20px;
                }
            }

            .left-panel {
                flex: 1;
                margin-right: 10px;

                @media only screen and (max-width: 750px) {
                    margin-right: 0;
                }

                .panel-item {
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    padding: 20px 0;

                    img {
                        margin-right: 20px;
                    }

                    .item-title {
                        font-weight: bold;
                        text-align: left;
                        font-size: 20px;
                        color: black;
                        line-height: 40px;

                        .bottom-indicate {
                            width: 80px;
                            height: 2px;
                            border-radius: 1px;
                            background: #a71e22;
                        }
                    }

                    .item-content {
                        margin-top: 20px;
                        text-align: left;
                        font-size: 16px;
                        font-family: arial;
                        line-height: 20px;
                        color: black;
                    }
                }

                .host-button {
                    padding-left: 20px;
                    padding-right: 20px;
                    margin-right: 20px;

                    @media only screen and (max-width: 750px) {
                        margin: auto;
                    }
                }

                .text {
                    font-size: 14px;
                    color: black;
                }

            }

            .right-panel {
                flex: 1;
                display: flex;
                margin-left: 10px;

                @media only screen and (max-width: 750px) {
                    margin-left: 0;
                }

                .panel-logo {
                    flex-shrink: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        .youtube {
            margin-top: 100px;
            width: 100%;
            height: 300px;

            @media only screen and (max-width: 750px) {
                margin-top: 0;
            }
        }

    }

}