.main-footer {
    .social-icons a {
        color: #777 !important;
    }

    .alarm-panel {
        width: 100%;
        margin-top: 100px;
        padding: 30px 10%;
        background: #e6e6e6;
        display: flex;
        justify-content: space-between;

        @media only screen and (max-width: 1200px) {
            display: block;
            padding: 10px;
        }

        @media only screen and (max-width: 750px) {
            margin-top: 0;
        }

        .item {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            color: #333;
            font-family: 'Open Sans';
            font-weight: bold;
            cursor: pointer;

            img {
                margin-right: 20px;

                @media only screen and (max-width: 750px) {
                    margin-right: 5px;
                    width: 20px;
                    display: block;
                }
            }
        }
    }

    .main-footer-content {
        width: 100%;
        background-color: #282828;
        padding: 20px;
        justify-content: center;
        text-align: center;
        align-items: center;

        a {
            transition: none;
            color: #EEE;

            &:hover {
                color: white;
            }
        }

        @media only screen and (max-width: 750px) {
            padding: 10px;
        }

        .like-panel {
            padding: 20px 20px 0 20px;
            color: #EEE;
            display: flex;
            justify-content: center;

            @media only screen and (max-width: 750px) {
                padding: 8px 8px 0 8px;
                display: block;
            }

            .row {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 20px;


                @media only screen and (max-width: 750px) {
                    margin: 8px;
                    display: inline-block !important;
                }
            }

            span {
                font-size: 28px;
                margin-right: 10px;
            }
        }


        .link-panel {
            color: #EEE;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;

            .row {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 20px;
            }

            span {
                font-size: 6px;
                margin: 0 10px;
            }

            @media only screen and (max-width: 750px) {
                display: flex;
                flex-direction: column;
                width: 100%;
                margin: 10px 0;

                span {
                    display: none;
                }
            }
        }

        .line {
            height: 1px;
            width: 70%;
            margin: auto;
            background: #999;
            margin: 20px auto;


            @media only screen and (max-width: 750px) {
                width: 100%;
                margin: 10px 0;
            }
        }

        .bottom-text {
            color: #EEE;
            font-size: 12px;
        }
    }
}