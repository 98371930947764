.img-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 50px;
  z-index: 1000;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.2s linear;

  display: flex;
  justify-content: center;
  align-items: center;
}

.image-view {
  /* background-color: #fff; */
  border-radius: 5px;
  margin: 0 auto;
  padding: 10px;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.view-container {
  max-width: 100%;
  height: 100%;
  margin: auto;
  position: relative;
}

.img-container {
  max-width: 100%;
  max-height: 100%;
  background: white;
  padding: 10px;
}

.visible {
  visibility: visible;
  opacity: 1;
}

.img-close-button {
  position: absolute;
  width: 40px;
  height: 40px;
  text-align: right;
  top: -20px;
  right: -20px;
  cursor: pointer;
  border-radius: 20px;
  background: #595959;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.img-close-button img {
  width: 100%;
  height: 100%;
}

.img-close-button:hover {
  color: #fa755a;
}

@media only screen and (max-width: 600px) {
  .img-backdrop {
    padding: 5px;
  }

  .image-view {
    padding: 5px
  }
}