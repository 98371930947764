.dashboard-content-container {
    position: relative;
    font-family: 'Roboto';
    padding: 50px 0;

    .loading-container {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 9999;
        background: rgba(255, 255, 255, 0.7);
        padding: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    @media only screen and (max-width: 750px) {
        padding: 20px 0;
    }

    .row {
        display: flex;
        margin: 0;
        
        @media only screen and (max-width: 750px) {
            display: block;
        }
    }

    .flex-center {
        justify-content: flex-start;
        align-items: center;
    }

    .event-title {
        font-size: 30px;
        font-weight: bold;
        color: black;
        text-align: left;
        margin-left: 10%;
        line-height: 40px;
        margin-bottom: 30px;
        font-family: 'Roboto';

        @media only screen and (max-width: 750px) {
            font-size: 24px;
            margin-left: 20px;
            margin-bottom: 10px;
        }
    }

    .dashboard-content {
        margin: auto;
        width: 80%;

        @media only screen and (max-width: 750px) {
            width: 96%;
            margin-top: 20px;
        }

        .detail-item, .upcoming-event {
            border: 1px solid #DDDDDD;
            width: 22%;
            margin-right: 3%;
            align-self: flex-start;

            @media only screen and (max-width: 750px) {
                width: 100%;
                margin-right: 0;
                margin-bottom: 10px;
            }

            .item-content {
                font-family: 'Open Sans';
                background: #f5f5f5;
                padding: 20px;

                .theme {
                    display: flex;
                    justify-content: space-between;
                    font-size: 14px;
                    font-weight: bold;
                }

                .content {
                    font-family: 'Open Sans';
                    font-size: 14px;
                    font-weight: bold;
                    border-top: 1px solid #DDDDDD;
                    padding-top: 20px;

                    .gain-icon {
                        width: 20px;
                        height: 20px;
                        margin-right: 10px;
                    }
                }

                .gain {
                    color: #000;
                    font-family: 'Roboto';
                    font-size: 30px;
                    font-weight: bold;
                    text-align: left;
                }
            }

            .action-pane {
                background: white;
                padding: 10px 20px;
            }

            .ticket-book-button {
                font-size: 14px;
            }
        }

        .upcoming-event {
            border: 1px solid #DDDDDD;
            flex: 1;
            margin-right: 0;
            font-family: 'Open Sans';
            background: #f5f5f5;
            padding: 20px;
            display: flex;
            flex-direction: column;

            .theme {
                display: flex;
                justify-content: space-between;
                font-size: 14px;
                font-weight: bold;

                .event-button {
                    color: #eb2732;
                    cursor: pointer;
                }
            }

            .content {
                font-family: 'Open Sans';
                font-size: 14px;
                font-weight: bold;
                display: flex;
                flex-direction: column;
                flex: 1;

                .clock-icon {
                    width: 20px;
                    height: 20px;
                    margin-right: 10px;
                }

                .clock-text {
                    font-size: 16px;
                    font-weight: bold;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #000;

                    span {
                        font-size: 8px;
                        margin: 0 5px;
                    }
                }

                .result-panel {
                    flex: 1;

                    .graph {
                        width: 60px;
                        height: 60px;

                        .CircularProgressbar .CircularProgressbar-path {
                            stroke: #eb2732;
                        }

                        .CircularProgressbar-text {
                            fill: #eb2732;
                            font-size: 24px;
                        }
                    }

                    .sold {
                        color: #029432;
                        margin-left: 20px;
                    }

                    .unsold {
                        color: #eb2732;
                        margin-left: 20px;
                    }
                }
            }

            .theme-title {
                color: #000;
                font-family: 'Roboto';
                font-size: 30px;
                font-weight: bold;
                text-align: left;
                cursor: pointer;
            }
        }

        .payment-info {
            border: 1px solid #DDDDDD;
            background: #f5f5f5;
            min-height: 300px;
            width: 100%;
            margin-top: 40px;
            padding: 20px;
            text-align: left;

            .payment-title {
                font-size: 16px;
                color: #000;
            }

            .content {
                text-align: center;
                color: #999999;

                .event-button {
                    color: #eb2732;
                    cursor: pointer;
                    margin-right: 5px;
                    font-weight: bold;
                }

                div {
                    line-height: 20px;
                }
            }
        }
    }
}