.seat-content-container {
    position: relative;
    font-family: 'Open Sans';
    scroll-behavior: smooth;

    .panel {
        border-radius: 4px;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
        background-color: #ffffff;
        border: solid 1px #dbdbdb;
        padding: 20px;
        overflow: hidden;
    }

    .panel-title {
        font-family: 'Roboto';
        font-size: 28px;
        text-align: left;
        font-weight: bold;
        color: #333;
        padding: 10px;
        padding-left: 20px;

        @media only screen and (max-width: 750px) {
            font-size: 18px;
            padding: 10px;
        }
    }

    .row {
        display: flex;
        margin: 0;
    }

    .flex-full {
        flex: 1;
    }

    .flex-center {
        justify-content: flex-start;
        align-items: center;
    }

    .seat-header {
        display: flex;
        padding: 0;
        margin: auto;
        z-index: 9999;
        width: 80%;
        margin: auto;
        padding-top: 50px;
        font-size: 16px;
        
        @media only screen and (max-width: 750px) {
            width: 96%;
            padding-top: 20px;
            font-size: 13px;
        }

        .path {
            display: flex;
            flex: 1;
            flex-wrap: wrap;
            @media only screen and (max-width: 750px) {
                text-align: left;
                display: block;
            }
        }

        img {
            width: 24px;
            height: 24px;
            margin-right: 10px;
        }

        .active {
            color: #a71e22;
            border-bottom: 3px solid #a71e22;
        }

        a:hover {
            color: #a71e22;
        }
    }

    .loading-container {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 9999;
        background: rgba(255, 255, 255, 0.7);
        padding: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .seat-content {
        margin: auto;
        width: 80%;
        padding-top: 50px;
        display: flex;
        padding-bottom: 60px;

        @media only screen and (max-width: 750px) {
            width: 96%;
            padding-top: 20px;
            display: block;
        }

        .payment-method {
            width: calc(75% - 20px);
            border: 1px solid #DDD;
            border-radius: 4px;
            background: #F9F9F9;
            text-align: left;
            display: flex;
            flex-direction: column;

            @media only screen and (max-width: 750px) {
                width: 100%;
                display: block;
            }

            .panel-title {
                padding-bottom: 0;
            }
            
            .panel-title-detail {
                font-size: 16px;
                font-weight: bold;
                padding-left: 20px;
                padding-bottom: 10px;

                @media only screen and (max-width: 750px) {
                    // font-weight: normal;
                    font-size: 14px;
                    padding-left: 10px;
                }    
            }

            .method-content {
                border-top: 1px solid #DDD;
                display: flex;
                color: #222;
                position: relative;
                justify-content: space-evenly;
                flex-wrap: wrap;

                div#chart {
                    width: 100%;
                }

                @media only screen and (max-width: 750px) {
                    display: block;
                }
            }

            .button {
                font-weight: bold;
                font-size: 14px;
                text-align: center;
                width: 140px;
            }

            .content-panel {
                flex: 1;
                padding: 10px 20px;

                @media only screen and (max-width: 750px) {
                    padding: 5px;
                    border-bottom: 1px solid #DDD;
                }

                .block-item {
                    display: flex;
                    font-size: 16px;

                    @media only screen and (max-width: 750px) {
                        font-size: 14px;
                    }

                    .item-left {
                        flex: 1;
                        display: flex;
                        align-items: center;

                        span {
                            font-size: 6px;
                            margin-left: 10px;
                            margin-right: 5px;
                        }
                    }

                    .item-right {
                        font-size: 18px;
                        font-weight: bold;
                        
                        @media only screen and (max-width: 750px) {
                            font-size: 14px;
                        }
                    }
                }
            }
        }

        .payment-summary {
            margin-left: 20px;
            border: 1px solid #DDD;
            border-radius: 4px;
            flex: 1;
            background: #F9F9F9;
            text-align: left;
            font-size: 16px;
            color: #222;
            min-height: 700px;

            .panel-title {
                @media only screen and (max-width: 750px) {
                    border-bottom: 1px solid #DDD;
                }
            }

            @media only screen and (max-width: 750px) {
                margin-left: 0px;
                margin-top: 10px;
                font-size: 13px;
                margin-bottom: 10px;
            }

            input {
                margin-right: 10px;
                height: 36px;
            }

            .button {
                font-size: 12px;
                font-weight: bold;
            }
    
            .block {
                border-bottom: 1px solid #DDD;
                font-size: 16px;
                padding: 5px 0;
                @media only screen and (max-width: 750px) {
                    font-size: 13px;
                }
            }

            .content-panel {
                flex: 1;
                margin: 0 20px;
                font-size: 16px;

                @media only screen and (max-width: 750px) {
                    margin: 0 10px;
                    padding: 10px 0;
                }

                .title {
                    display: flex;
                    align-items: center;
                    font-weight: bold;
                    font-size: 16px;

                    span {
                        color: #44bd32;
                        font-size: 20px;
                        margin-right: 10px;
                    }
                }

                .item {
                    display: flex;
                    font-size: 16px;
                    font-weight: bold;
                    color: #44bd32;
                }
            }
        }
    }
}