.stepper {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Rubik;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #282828;
    cursor: normal;

    @media only screen and (max-width: 700px) {
        font-size: 16px;
    }

    .minus {
        background: url('../../../assets/images/round-minus@3x.png') no-repeat;
        width: 22px;
        height: 22px;
        cursor: pointer;
        background-size: contain;

        &:hover {
            background: url('../../../assets/images/round-minus-active@3x.png') no-repeat;
            width: 22px;
            height: 22px;
            cursor: pointer;
            background-size: contain;
        }

        @media only screen and (max-width: 700px) {
            width: 18px;
            height: 18px;
         
            &:hover {
                width: 18px;
                height: 18px;
            }
        }
    }

    .plus {
        background: url('../../../assets/images/round-plus@3x.png') no-repeat;
        width: 22px;
        height: 22px;
        cursor: pointer;
        background-size: contain;

        &:hover {
            background: url('../../../assets/images/round-plus-active@3x.png') no-repeat;
            width: 22px;
            height: 22px;
            cursor: pointer;
            background-size: contain;
        }

        @media only screen and (max-width: 700px) {
            width: 18px;
            height: 18px;
         
            &:hover {
                width: 18px;
                height: 18px;
            }
        }
    }

    .value {
        margin: 0 10px;
        min-width: 20px;
        text-align: center;
    }
}