.main-content {
    position: relative;
    height: calc(100% - 72px);
    top: 72px;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;

    @media only screen and (max-width: 750px) {
        top: 50px;
        height: calc(100% - 50px);
        z-index: 99;
    }
}