.contact-content-container {
    font-family: 'Open Sans';
    color: #000;

    .contact-content {
        display: flex;
        flex-direction: column;
        text-align: center;
        margin: auto;
        width: 80%;

        .panel-title {
            font-family: 'Roboto';
            font-size: 24px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            padding: 20px 0 10px 0;
            margin-top: 50px;

            @media only screen and (max-width: 600px) {
                font-size: 20px;
                padding: 15px 0;
                margin-top: 10px;
            }
        }

        .panel-content {
            margin-top: 20px;
            font-size: 16px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            // max-width: 400px;
            width: 100%;
            margin: auto;
            display: flex;
            flex-direction: column;

            .panel-description {
                margin-bottom: 20px;
                text-align: left;
            }

            input,
            textarea {
                display: block;
                margin: 10px auto;
                width: 100%;
                // max-width: 400px;
                padding: 8px;
                border-radius: 4px;
                border: 1px solid #DDD;
                font-size: 14px;
            }

            @media only screen and (max-width: 600px) {
                font-size: 14px;
                font-weight: bold;
                padding: 10px;

                .panel-description {
                    margin-bottom: 10px;
                }

                input,
                textarea {
                    width: 100%;
                }
            }

        }

        .button {
            font-weight: normal;
            font-size: 14px;
            padding: 10px;
            margin-top: 20px;
        }

        .contact-info {
            display: flex;
            margin-top: 50px;
            margin-bottom: 20px;
            justify-content: space-around;
            align-items: center;

            .contact-info-item {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                font-family: Rubik-Light;
                font-size: 20px;
                font-weight: bold;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;

                img {
                    height: 50px;
                    margin-bottom: 20px;
                    display: block;
                }
            }

            @media only screen and (max-width: 600px) {
                margin-top: 10px;
                margin-bottom: 10px;

                .contact-info-item {
                    font-size: 16px;

                    img {
                        height: 30px;
                        margin-bottom: 10px;
                        display: block;
                    }
                }
            }
        }
    }
}