.home-content-container {
    position: relative;
    font-family: 'Roboto';

    .panel {
        border-radius: 4px;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
        background-color: #ffffff;
        border: solid 1px #dbdbdb;
        padding: 20px;
        overflow: hidden;
    }

    .row {
        display: flex;
    }

    .flex-center {
        justify-content: flex-start;
        align-items: center;
    }

    .home-content {
        margin: auto;

        .main-carousel {
            
            .carousel-empty {
                background: #111;
                height: 531px;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .control-dots {
                background: #000000a6;
                margin: 0;
                padding: 5px;
                float: left;
                width: unset;
                left: 50%;
                border-radius: 10px 10px 0 0;

                .dot {
                    background: transparent;
                    border: 1px solid white;
                    width: 12px;
                    height: 12px;
                    opacity: 1;
                }

                .dot.selected {
                    background: white;
                }
            }
        }

        .itemWrapper {
            padding: 0 !important;
        }

        .rcs_sliderBox {
            background: white !important;
        }

        .rcs_slideCon {
            height: 100% !important;
        }

        .event-title {
            font-size: 30px;
            font-weight: bold;
            color: black;
            text-align: left;
            margin-left: 10%;
            line-height: 40px;
            margin-bottom: 30px;
            font-family: 'Roboto';

            @media only screen and (max-width: 750px) {
                font-size: 24px;
                margin-left: 20px;
                margin-bottom: 10px;
            }
        }

        .carousel {
            position: relative;
            min-height: 300px;
            margin-top: -2px;
            height: 531px;

            .carousel-empty {
                background: #111;
                height: 531px;
                
                @media only screen and (max-width: 750px) {
                    height: 240px;
                }

                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .rcs_buttonWrapper {
                display: none;
            }

            @media only screen and (max-width: 750px) {
                height: 240px;
                min-height: unset;
                background: #f9f9f9;
                border-bottom: 1px solid #dbdbdb;
                margin: 0 !important;
            }

            .title-text {
                font-size: 44px;
                font-weight: bold;
                color: white;
                max-width: 50%;
                line-height: 60px;
                margin: 0;
                margin-bottom: 20px;
                height: unset;
                
                @media only screen and (max-width: 750px) {
                    font-size: 20px;
                    max-width: unset;
                    line-height: 20px;
                    margin-bottom: 5px;
                }            
            }
    
            .rcs_dotsSet {
                margin: 15px auto;
                color: black;
                margin-top: -41px !important;
                z-index: 1000;
                position: absolute;
                height: 40px;
                width: 100px;
                left: calc(50% - 50px);
                background: #000A;
                border-radius: 8px 8px 0 0;
            }
            
            .rcs_slideCon {
                background: #272727;
            }

            @media only screen and (max-width: 750px) {
                margin: 0 10px;

                .rcs_sliderBox {
                    height: 240px !important;
                }

                .slider-item {
                    overflow: hidden;
                    background: #111;
                }

                .rcs_slideCon .itemWrapper img {
                    background: #111;
                }

            }
            
            .banner-title {
                position: absolute;
                padding-top: 10%;
                padding-left: 10%;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background: #0001;
                text-align: left;
            
                @media only screen and (max-width: 750px) {
                    padding: 10px;
                }
                
                .book-button {
                    border-color: white;
                    background: transparent;
                    width: 140px;
                    text-align: center;
                    font-weight: bold;
                    height: unset;
                }
            }
        }

        .banner-ads {
            width: 80%;
            height: 160px;
            margin: 0 auto;
            margin-top: 100px;
            padding: 0;

            @media only screen and (max-width: 750px) {
                margin-top: 50px;
                width: 96%;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .popular-event {
            margin-top: 100px;
            font-family: 'Open Sans';
            min-height: 200px;

            @media only screen and (max-width: 750px) {
                margin-top: 50px;
            }

            .rcs_sliderBox {
                height: 240px !important;
                @media only screen and (max-width: 750px) {
                    height: 160px !important;
                }
            }

            .itemWrapper {
                overflow: hidden;
                border-radius: 5px;

                @media only screen and (max-width: 750px) {
                    height: 156px !important;
                }
            }

            .event-list {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                margin: 50px 10%;
                
                @media only screen and (max-width: 750px) {
                    display: block;
                    margin: 0;
                }

                .ticket-item {
                    font-family: 'Open Sans';
                    width: 30%;
                    margin-right: 1%;
                    height: auto;
                    position: relative;
                    margin-bottom: 40px;
                    border-radius: 8px;
                    overflow: hidden;

                    @media only screen and (max-width: 750px) {
                        width: 96%;
                        margin: 20px auto;
                    }    

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
    
                    .action-pane {
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        z-index: 9999;
                        padding: 10px 20px;
                        background: rgba(0, 0, 0, 0.9);
                        color: white;
                        cursor: pointer;
                        height: unset;

                        .ticket-title {
                            font-size: 18px;
                            text-align: left;
                            height: 30px;
                            line-height: 23px;
                            color: white;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                            
                            @media only screen and (max-width: 750px) {
                                font-size: 16px;
                            }    
                        }

                        .row {
                            height: unset;
                            margin: 0;
                        }

                        .ticket-price {
                            background: white;
                            border-radius: 4px;
                            color: #eb2732;
                            font-weight: bold;
                            font-size: 11px;
                            height: 18px;
                            padding: 0 10px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-right: 10px;
                            height: unset;
                        }

                        .ticket-time {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: unset;

                            span {
                                font-size: 4px;
                                margin: 0 5px;
                                height: unset;
                            }
                        }

                        .ticket-book-button {
                            display: none;
                            margin-top: 10px;
                            font-weight: bold;
                        }

                        &:hover {
                            .ticket-book-button {
                                display: block;
                            }
                        }

                        @media only screen and (max-width: 750px) {
                            .ticket-book-button {
                                display: block;
                            }
                        }
                    }
                }
            }
        }

        .category {
            margin-top: 100px;

            @media only screen and (max-width: 750px) {
                margin-top: 50px;
            }

            .line {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: nowrap;
                
                @media only screen and (max-width: 750px) {
                    flex-wrap: wrap;
                }
                
            }

            .category-item {
                padding: 10px 40px;
                margin: 10px;
                font-size: 16px;
                font-weight: bold;
                color: black;
                background: #f9f9f9;

                @media only screen and (max-width: 750px) {
                    padding: 5px;
                    margin: 5px;
                }
            }

            .link {
                color: #a71e22;
                font-weight: bold;
                span {
                    cursor: pointer;
                }
            }
        }

        .host-event-panel {
            margin: auto;
            background: #f9f9f9;
            display: flex;
            padding: 50px 10%;
            padding-top: 100px;

            @media only screen and (max-width: 750px) {
                padding-top: 50px;
                padding: 10px;
                display: block;
            }

            .event-title {
                margin: 0;
            }

            .ctrl-panel {
                display: flex;
                justify-content: left;
                align-items: left;
                margin-left: 70px;
                
                @media only screen and (max-width: 750px) {
                    display: block;
                    text-align: center;
                    margin-left: 0;
                    margin-bottom: 20px;
                }
            }

            .left-panel {
                flex: 1;
                margin-right: 10px;
                
                @media only screen and (max-width: 750px) {
                    margin-right: 0;
                }

                .panel-item {
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    padding: 20px 0;

                    img {
                        margin-right: 20px;
                    }

                    .item-title {
                        font-weight: bold;
                        text-align: left;
                        font-size: 20px;
                        color: black;
                        line-height: 40px;

                        .bottom-indicate {
                            width: 80px;
                            height: 2px;
                            border-radius: 1px;
                            background: #a71e22;
                        }
                    }

                    .item-content {
                        margin-top: 20px;
                        text-align: left;
                        font-size: 16px;
                        font-family: arial;
                        line-height: 20px;
                        color: black;
                    }
                }

                .host-button {
                    padding-left: 20px;
                    padding-right: 20px;
                    margin-right: 20px;

                    @media only screen and (max-width: 750px) {
                        margin: auto;
                    }    
                }
                
                .text {
                    font-size: 14px;
                    color: black;
                }

            }

            .right-panel {
                flex: 1;
                display: flex;
                margin-left: 10px;

                @media only screen and (max-width: 750px) {
                    margin-left: 0;
                }

                .panel-logo {
                    width: 100%;
                    height: 100%;

                    img {
                        flex-shrink: 0;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }

        .more-event {
            width: 80%;
            display: flex;
            margin: 50px auto;

            @media only screen and (max-width: 750px) {
                width: 100%;
                display: block;
            }

            .left {
                flex: 3;
                margin-right: 20px;
                background: #f9f9f9;

                @media only screen and (max-width: 750px) {
                    margin-right: 0;
                    display: block;
                }
    
                .item {
                    display: flex;
                    overflow: hidden;
                    
                    @media only screen and (max-width: 750px) {
                        display: block;
                    }

                    img {
                        width: 50%;
                        max-height: 300px;
                        object-fit: cover;
                        
                        @media only screen and (max-width: 750px) {
                            width: 96%;
                        }
                    }

                    .detail {
                        width: 50%;
                        text-align: left;
                        padding: 20px;
                   
                        @media only screen and (max-width: 750px) {
                            width: 96%;
                            padding: 0;
                            margin: auto;
                            margin-bottom: 20px;
                        }

                        .detail-title {
                            font-size: 20px;
                            color: black;
                            font-weight: bold;
                        }

                        .detail-content {
                            font-size: 14px;
                            color: #282828;
                            line-height: 20px;
                        }

                        .more-detail {
                            text-align: center;
                            width: 140px;
                            font-weight: bold;
                            height: 40px;
                            justify-content: center;
                            align-items: center;
                            display: flex;
                        }
                    }
                }
            }

            .right {
                flex: 2;
                margin-left: 20px;
                background: #f9f9f9;
                
                @media only screen and (max-width: 750px) {
                    margin-left: 0;
                    display: block;
                }
    
                .item {
                    display: flex;
                    height: 140px;

                    @media only screen and (max-width: 750px) {
                        margin: 10px;
                    }

                    img {
                        margin-right: 20px;
                        width: 40%;
                        height: 100%;
                        object-fit: cover;
                        
                        @media only screen and (max-width: 750px) {
                            margin-right: 5px;
                        }
                    }

                    .detail {
                        padding: 10px; 
                        text-align: left;

                        .donate {
                            margin-top: 20px;
                            text-align: center;
                            width: 160px;
                            font-weight: bold;
                            height: 40px;
                            justify-content: center;
                            align-items: center;
                            display: flex;
                        }

                        .detail-title {
                            font-size: 20px;
                            color: black;
                            font-weight: bold;
                            line-height: 24px;
                        }

                        .detail-content {
                            font-size: 16px;
                            color: #282828;
                            line-height: 20px;
                            margin-top: 10px;
                        }
                    }
                }
            }
        }

        .youtube {
            margin-top: 100px;
            width: 100%;
            height: 300px;

            @media only screen and (max-width: 750px) {
                margin-top: 0;
            }
        }

    }

}