.App {
  text-align: center;
  position: relative;

  // div {
  //   position: relative;
  // }

  .App-Content {
    max-width: 1440px;
    margin: auto;
  }
}


.Toastify__toast-container--top-center {
  top: 120px !important;
  font-size: 18px;
}