.payments-container {
    text-align: center;
    min-height: 500px;
    padding: 50px;
    font-family: 'Roboto';
    
    .loading-container {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 9999;
        background: rgba(255, 255, 255, 0.7);
        padding: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .search-input {
        border: 1px solid #CCC;
        border-radius: 5px;
        width: 100%;
        height: 30px;
        padding: 3px;
    }

    @media only screen and (max-width: 700px) {
        padding: 10px;
    }

    .total-amount {
        text-align: right;
        font-size: 17px;
        font-weight: bold;
    }

    table, tr, td {
        border: 1px solid #CCC;
    }

    .header {
        background: #EEE;
        font-weight: bold;
        font-size: 17px;

        td {
            padding: 3px;
        }

        @media only screen and (max-width: 700px) {
            font-size: 13px;
        }
    }

    table {
        width: 100%;
        font-size: 16px;

        @media only screen and (max-width: 700px) {
            font-size: 12px;
        }
    }

    .table-container {
        display: flex;
        flex: auto;
        overflow: auto;
        border: 1px solid #CCC;
    }

    .link {
        cursor: pointer;
        color: rgb(64, 90, 241);
        &:hover {
            color: rgb(120, 139, 245);
        }
    }
}