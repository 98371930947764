.radio-button {
    display: flex;
    margin: 10px 0px;
    // justify-content: center;
    align-items: center;
    cursor: pointer;

    .check {
        border-radius: 7px;
        border: 2px solid #a71e22;
        width: 14px;
        height: 14px;
        margin-right: 7px;
    }

    .checked {
        background: #a71e22;
        margin: 1px;
        border-radius: 4px;
        width: 8px;
        height: 8px;
    }
}