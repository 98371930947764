.tickets-content-container {
    position: relative;
    font-family: 'Open Sans';
    scroll-behavior: smooth;
    
    .loading-container {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 9999;
        background: rgba(255, 255, 255, 0.7);
        padding: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .bg-gray {
        background: #F9F9F9;
        border: 1px solid #DDD;
        border-radius: 4px;
        text-align: left;
    }

    .button {
        font-size: 13px;
        font-weight: bold;
    }

    .content {
        width: 80%;
        margin: 0 auto;

        @media only screen and (max-width: 750px) {
            width: 96%;
        }
    }

    .row {
        display: flex;
        margin: 0 auto;
        align-items: flex-start;

        @media only screen and (max-width: 750px) {
            display: block;
        }
    }

    .panel-left {
        width: calc(75% - 20px);
        margin-right: 10px;
        
        @media only screen and (max-width: 750px) {
            width: 100%;
            margin: 0;
        }
    }

    .panel-right {
        margin-left: 10px;
        width: 25%;
        
        @media only screen and (max-width: 750px) {
            width: 100%;
            margin: 0;
            margin-top: 10px;
        }
    }

    .top-padding {
        padding-top: 50px;
        @media only screen and (max-width: 750px) {
            padding-top: 10px;
        }
    }

    .top-padding-2x {
        padding-top: 100px;
        @media only screen and (max-width: 750px) {
            padding-top: 20px;
        }
    }

    .top-margin {
        margin-top: 50px;
        @media only screen and (max-width: 750px) {
            margin-top: 10px;
        }
    }

    .top-margin-2x {
        margin-top: 100px;
        @media only screen and (max-width: 750px) {
            margin-top: 20px;
        }
    }
    
    .share-items {
        img {
            width: 40px;
            height: 40px;
            cursor: pointer;
            margin: 10px;
        }
    }

    .panel-title {
        font-family: 'Roboto';
        font-size: 28px;
        text-align: left;
        font-weight: bold;
        color: #333;
        padding: 10px;
        padding-left: 20px;

        @media only screen and (max-width: 750px) {
            font-size: 20px;
        }

        p {
            font-size: 16px;
            display: flex;
            align-items: center;
            margin: 0;

            span {
                margin: 0 5px;
            }

            span.fa-clock-o {
                color: #a71e22;
            }
        }
    }

    .panel-share {
        font-size: 16px;
        padding: 10px;
    }

    .payment-confirm {
        width: 100%;

        .panel-title {
            color: #44bd32;
            font-size: 24px;
            border-bottom: 1px solid #DDD;

            @media only screen and (max-width: 750px) {
                font-size: 20px;
            }
        }

        .book-confirm-content {
            background: white;
        }

        .block {
            border-bottom: 1px solid #DDD;
            padding: 20px;

            @media only screen and (max-width: 750px) {
                padding: 10px;
            }

            img {
                margin-right: 10px;
                width: 50px;
                height: 50px;

                @media only screen and (max-width: 750px) {
                    display: none;
                }
            }

            img.img-btn {
                cursor: pointer;
                @media only screen and (max-width: 750px) {
                    display: block;
                }

                &:hover {
                    border: 2px solid white;
                }
            }
        }

        .small-height {
            height: 24px;
        }

        .medium-height {
            height: 30px;
        }

        .border-top {
            border-top: 1px solid #EEE;
        }

        .border-bottom {
            border-bottom: 1px solid #EEE;
        }

        .padding-top-small {
            padding-top: 5px;
        }

        .padding-bottom-small {
            padding-bottom: 5px;
        }

        .block-title {
            font-weight: bold;
            font-size: 16px;
            color: #333;
            margin-bottom: 10px;

            @media only screen and (max-width: 750px) {
                font-size: 14px;
                line-height: 20px;
            }

        }

        .block-detail {
            font-size: 12px;
            font-weight: bold;
            color: #888;
            line-height: 16px;
        }

        .red {
            color: #a71e22;
        }

        .block-item {
            font-size: 14px;
            font-weight: bold;

            .row {
                padding: 10px 0;
                align-items: center;
            }

            .email-input {
                margin-right: 20px;
                border: 1px solid #CCC;
                border-radius: 4px;
                padding: 0 5px;
                width: 220px;
                
                @media only screen and (max-width: 750px) {
                    margin-right: 0;
                    width: 100%;
                }
            }

            .btn-row {
                display: flex;
                @media only screen and (max-width: 750px) {
                    margin-left: calc(100% - 230px);
                    margin-top: 10px;
                }
            }
        }

        .button {
            width: 100px;
            text-align: center;
        }
    }

    .payment-summary {
        
        @media only screen and (max-width: 750px) {
            margin-bottom: 10px;
        }

        .block {
            border-bottom: 1px solid #DDD;
            font-size: 16px;
            padding: 5px 0;
        }

        .content-panel {
            flex: 1;
            margin: 0 20px;
            font-size: 16px;

            .title {
                display: flex;
                align-items: center;
                font-weight: bold;
                font-size: 16px;

                span {
                    color: #44bd32;
                    font-size: 20px;
                    margin-right: 10px;

                }
            }

            .item {
                display: flex;
                font-size: 20px;
                font-weight: bold;
                color: #44bd32;

            }
        }
    }

    .loading-container {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 9999;
        background: rgba(255, 255, 255, 0.7);
        padding: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}