.report-dialog {

    background: #0008;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    justify-content: center;
    align-items: center;
    z-index: 99999;
    font-family: 'Open Sans';

    .close-dlg-button {
        color: #a71e22;
        text-align: right;
        font-family: 'Roboto';

        img {
            cursor: pointer;
            width: 20px;
            height: 20px;
        }
    }

    .report-content {
        background: white;
        width: 60%;
        height: 60%;
        overflow: hidden;
        border-radius: 4px;
        display: flex;
        flex-direction: column;

        .report-title {
            padding: 20px 10px;
            display: flex;
            align-items: center;
            background: #f1f2f6;

            .title {
                font-size: 30px;
                flex: 1;
                line-height: 30px;
                font-weight: bold;
                color: #000;
                text-align: left;
                font-family: 'Roboto';
            }

            img {
                width: 30px;
                height: 30px;
                padding: 5px;
                cursor: pointer;
            }
        }

        .action-pane {
            border-top: 1px solid #dddddd;
            padding: 10px 20px;
            text-align: right;
            display: flex;
        }

        @media only screen and (max-width: 750px) {
            width: 90%;
            height: 90%;
            display: block;
        }
    }

}