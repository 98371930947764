.sign-in-dialog {

    background: #0008;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
    font-family: 'Open Sans';

    .close-dlg-button {
        color: #a71e22;
        text-align: right;
        font-family: 'Roboto';

        img {
            cursor: pointer;
            width: 20px;
            height: 20px;
        }
    }

    .sign-in-content {
        background: white;
        display: flex;
        width: 60%;
        height: 60%;
        overflow: hidden;
        border-radius: 4px;

        @media only screen and (max-width: 750px) {
            width: 90%;
            height: 90%;
            display: block;
        }

        .logo-contain-view {
            flex: 1;
            overflow: hidden;

            @media only screen and (max-width: 750px) {
                height: 30%;
            }

            .sign-in-logo {
                flex: 1;
                display: flex;

                .panel-logo {
                    flex-shrink: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        .sign-in-text {
            width: 50%;
            padding: 20px;
            display: flex;
            flex-direction: column;
        
            @media only screen and (max-width: 750px) {
                width: 100%;
                padding: 10px;
            }

            .sign-in-title {
                font-size: 15px;
                font-weight: bold;
                line-height: 20px;
                margin-bottom: 40px;
                
                @media only screen and (max-width: 750px) {
                    margin-bottom: 20px;
                }
            }

            .sign-in-detail {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 30px;
                
                @media only screen and (max-width: 750px) {
                    padding: 10px;
                }
    
                .button {
                    width: 80%;
                    margin-bottom: 15px;
                }

                .space {
                    margin-bottom: 25px;
                    margin-top: 10px;
                                    
                    @media only screen and (max-width: 750px) {
                        margin-bottom: 10px;
                    }
                }

                .description {
                    color: #a71e22;
                    line-height: 15px;
                    font-weight: bold;
                }
            }
        }
    }

}