.check-box {
    $brand: #ea2027;
    $checkboxWidth: 19px;
    $checkboxSize: 16px;
    $uncheckedBG: transparent;
    $checkedBG: $brand;
    $borderColor: #e1e1e1;
    $checkedColor: black;
    $disabledBG: #f1f1f1;
    justify-content: center;

    .check-box-input {
        display: none;
    }

    label {
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        float: left;
    }

    .check-box-input {

        + span {
            color: red;
            display: flex;
            width: $checkboxWidth;
            height: $checkboxSize;
            background: $uncheckedBG;
            align-items: center;
            justify-content: center;
            color: white;
            margin: 5px;
            border: 1px solid $borderColor;
            // box-sizing: border-box;
            transition: all 150ms;
            border-radius: 3px;
            min-height: 0;
        }

        &:checked {
            + span {
                position: relative;
                border-width: $checkboxSize / 2;
                background: $checkedBG;
                border: unset;

                &::after {
                    content: ' ';
                    position: absolute;
                    left: 4px;
                    top: 1px;
                    width: 7px;
                    height: 11px;
                    border: solid white;
                    border-width: 0 2px 2px 0;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                }
            }
        }

        &:disabled {
            + span {
                background: $disabledBG;
            }
        }
    }
}