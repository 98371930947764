.ticket-template-content {
    text-align: left;
    background: white;

    .ticket-red-header {
        width: 100%;
        // justify-content: center;
        // align-items: flex-end;
        // display: flex;
        background: #a71e22;
        padding: 15px 30px;

        color: white;

        img {
            height: 75px;
            width: auto;

            @media only screen and (max-width: 700px) {
                height: 30px;
            }
        }

        .header-title {
            font-size: x-large;

            @media only screen and (max-width: 700px) {
                font-size: 20px;
            }
        }
    }

    .line {
        width: 100%;
        height: 1px;
        background: #feeeed;
    }

    .flex {
        display: flex;

        @media only screen and (max-width: 700px) {
            display: block;
        }
    }

    .ticket-block {
        padding: 30px;

        .qr-code {
            @media only screen and (max-width: 700px) {
                width: 100%;
                text-align: center;
            }
        }

        .ticket-detail {
            padding-left: 50px;
            flex: 1;

            @media only screen and (max-width: 700px) {
                padding-left: 0;
            }
        }
    }

    .ticket-title {
        padding: 0 0 15px 0;
        font-size: 24px;
        line-height: 28px;
        font-weight: bold;
        font-family: Calibri;
    }

    .ticket-content {
        font-size: 16px;
        line-height: 22px;
        font-family: Calibri;
    }

    .detail-title {
        width: 30%;

        @media only screen and (max-width: 700px) {
            width: 40%;
        }
    }

    .fix {
        height: auto;
        width: 100%;
    }

    .button-link {
        margin-top: 10px;
        text-align: center;

        a,
        a:hover {
            color: white;
            font-size: 14px;
            font-weight: bold;
        }
    }

    .footercopy {
        width: 100%;
        justify-content: center;
        align-items: flex-end;
        display: flex;
        background: #a71e22;
        padding: 15px;
        // font-size: 18px;
        // font-weight: bold;
        color: white;

        a,
        a:hover {
            color: white;
            // font-size: 16px;
            // font-weight: bold;
        }
    }
}