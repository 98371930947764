.book-content-container {
    position: relative;
    font-family: 'Roboto';
    scroll-behavior: smooth;

    .button {
        font-weight: normal !important;
    }

    .panel {
        border-radius: 4px;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
        background-color: #ffffff;
        border: solid 1px #dbdbdb;
        padding: 20px;
        overflow: hidden;
    }

    .panel-title {
        font-size: 24px;
        text-align: left;
        font-weight: bold;
        color: #333;
        margin-bottom: 10px;

        @media only screen and (max-width: 750px) {
            font-size: 18px;
            margin-top: 10px;
        }
    }

    .row {
        display: flex;
        margin: 0;
    }

    .flex-center {
        justify-content: flex-start;
        align-items: center;
    }

    .book-header {
        border-radius: 0;
        border: none;
        display: flex;
        padding: 0;
        padding-left: 10%;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
        position: fixed;
        top: 72px;
        width: 100%;
        max-width: 1440px;
        margin: auto;
        z-index: 9999;

        @media only screen and (max-width: 750px) {
            display: none;
        }

        .menu-item {
            font-size: 14px;
            font-family: Roboto;
            cursor: pointer;
            padding: 10px;
            color: #AAA;
            border-top: 3px solid transparent;
            border-bottom: 3px solid transparent;
            min-width: 140px;

            @media only screen and (max-width: 750px) {
                padding: 5px;
                min-width: unset;
            }

            &:hover {
                color: #a71e22;
                border-bottom: 3px solid #a71e22;
            }
        }

        .active {
            color: #a71e22;
            border-bottom: 3px solid #a71e22;
        }
    }

    .loading-container {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 99999;
        background: white;
        padding: 100px;
    }

    .book-nav-header {
        display: flex;
        padding: 0;
        margin: auto;
        z-index: 9999;
        width: 80%;
        margin: auto;
        padding-top: 100px;
        font-size: 16px;
        
        @media only screen and (max-width: 750px) {
            width: 96%;
            padding-top: 20px;
            font-size: 13px;
        }

        .path {
            display: flex;
            flex: 1;
            flex-wrap: wrap;
        }

        img {
            width: 24px;
            height: 24px;
            margin-right: 10px;
        }

        .active {
            color: #a71e22;
            border-bottom: 3px solid #a71e22;
        }

        a:hover {
            color: #a71e22;
        }
    }

    .book-content {
        margin: auto;
        width: 80%;
        padding-top: 30px;
        
        @media only screen and (max-width: 750px) {
            width: 96%;
        }

        .img-contain-view {
            width: 100%;
            overflow: hidden;
            margin-right: 10px;
            border-radius: 8px;

            img {
                flex-shrink: 0;
                object-fit: cover;
                border-radius: 4px;
                width: 100%;
                height: auto;
            }
        }

        .banner-img {
            width: 100%;
            margin: 0 auto;
            padding: 0;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            
            @media only screen and (max-width: 750px) {
                display: block;
            }

            .left-banner-panel {
                width: calc(75% - 20px);

                .img-contain-view {
                    margin-bottom: 50px;
                }
                
                @media only screen and (max-width: 750px) {
                    width: 100%;
                }
            }

            .share-panel {
                width: 25%;
                font-size: 16px;
                text-align: left;
                font-family: 'Roboto';

                .content-panel {
                    padding: 10px;
                    border: 1px solid #DDD;
                    border-radius: 4px;
                    background: #f9f9f9;
                    margin-left: 20px;
                }

                @media only screen and (max-width: 750px) {
                    position: absolute;
                    top: 20px;
                    width: 96%;
                    margin: auto;
                    margin-bottom: 20px;
                }

                .share-items {
                    img {
                        width: 40px;
                        height: 40px;
                        cursor: pointer;
                        margin: 10px;
                    }
                }
            }
        }

        .book-detail {
            margin-bottom: 50px;

            @media only screen and (max-width: 750px) {
                margin-bottom: 10px;
            }

            img {
                width: 20px;
                margin-right: 10px;
            }

            .detail-text {
                font-size: 20px;
                color: #111;
                text-align: left;

                p {
                    margin: 0;
                }
                
                @media only screen and (max-width: 750px) {
                    font-size: 14px;
                }

                span {
                    font-size: 8px;
                    margin: 0 5px;
                }
            }
        }

        .ticket-list {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            text-align: left;
            margin-bottom: 50px;

            @media only screen and (max-width: 750px) {
                display: block;
            }

            .list {
                flex: 1;
                margin-right: 10px;
                border: 1px solid #DDD;
                border-radius: 4px;

                @media only screen and (max-width: 750px) {
                    margin-right: 0;
                }

                .list-header {
                    background: #f9f9f9;
                    padding: 10px 20px;
                    font-size: 24px;
                    font-weight: bold;
                    color: #333;

                    @media only screen and (max-width: 750px) {
                        padding: 5px 10px;
                        font-size: 18px;
                    }
                }

                .list-content {
                    font-family: 'Roboto';

                    .ticket-list-item {
                        display: flex;
                        padding: 18px;
                        border-top: 1px solid #DDD;
                        justify-content: center;
                        align-items: center;

                        @media only screen and (max-width: 750px) {
                            padding: 10px;
                        }

                        h4 {
                            flex: 1;
                            margin: 0;
                            font-family: 'Open Sans';
                            font-size: 18px;
                            font-weight: bold;
                            letter-spacing: 0;

                            @media only screen and (max-width: 750px) {
                                font-size: 14px;
                                margin-right: 10px;
                            }

                            span {
                                font-size: 10px;
                                font-weight: bold;
                                border: 1px solid #a71e22;
                                border-radius: 4px;
                                padding: 0 10px;
                                margin-left: 20px;
                                background: transparent;
                                color: #a71e22;
                            }
                        }

                        span {
                            font-size: 18px;
                            font-weight: bold;
                            color: #333333;
                            text-align: right;
                            margin-right: 20px;
                            
                            @media only screen and (max-width: 750px) {
                                font-size: 14px;
                                margin-right: 5px;
                            }
                        }

                        .react-numeric-input {
                            margin-left: 20px;
                                             
                            @media only screen and (max-width: 750px) {
                                margin-left: 5px;
                            }

                            input {
                                height: 44px;
                                width: 80px;
                                border-color: #a71e22 !important;
                                border-radius: 4px !important;
                                color: #a71e22;
                                text-align: center;
                                
                                @media only screen and (max-width: 750px) {
                                    height: 36px;
                                }
                            }

                            b {
                                background: transparent !important;
                                i {
                                    border-color: transparent transparent rgb(192, 42, 39) !important;
                                }
                            }

                            b:last-child {
                                background: transparent !important;
                                i {
                                    border-color: rgb(192, 42, 39) transparent transparent !important;
                                }
                            }

                        }
                    }

                    .disabled {
                        h4 {
                            color: #AAA;
                        }

                        span {
                            color: #AAA;
                        }

                        .react-numeric-input {

                            input {
                                color: #c27976;
                                background: #f8f8f8;
                                border-color: #c27976 !important;
                            }
                        }
                    }
                }

                .list-footer {
                    border-top: 1px solid #DDD;
                    background: #f9f9f9;
                    padding: 10px 20px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    @media only screen and (max-width: 750px) {
                        padding: 5px;
                    }

                    span {
                        font-size: 12px;
                        flex: 1;
                        color: #AAA;
                    }

                    .button {
                        width: 150px;
                        text-align: center;
                        font-size: 15px;
                        font-weight: bold;
                        
                        @media only screen and (max-width: 750px) {
                            width: 100px;
                            font-size: 13px;
                        }
                    }
                }
            }

            .contact-host {
                width: 25%;
                margin-left: 10px;
                // line-height: 20px;

                .content-panel {
                    padding: 20px;
                    background: #f9f9f9;
                    border: 1px solid #DDD;
                    border-radius: 4px;    
                }
                
                @media only screen and (max-width: 750px) {
                    width: 100%;
                    padding: 10px;
                    margin: 10px auto;
                }

                .button {
                    margin-top: 10px;
                    text-align: center;
                    font-weight: bold;
                    font-size: 15px;
                                            
                    @media only screen and (max-width: 750px) {
                        font-size: 13px;
                    }
                }
            }
        }

        .overview {
            margin-bottom: 50px;

            .panel-detail {
                width: calc(75% - 20px);
                margin-right: 10px;
                text-align: left;
                font-size: 18px;
                color: #555;
                line-height: 20px;
                font-family: Open Sans;
                                        
                @media only screen and (max-width: 750px) {
                    width: 100%;
                    font-size: 13px;
                }
            }

            .img-line {
                display: flex;
                flex-wrap: wrap;

                .img-contain-view {
                    width: 200px;
                    height: 200px;
                    margin: 0 20px 20px 0;

                    @media only screen and (max-width: 750px) {
                        width: calc(50% - 10px);
                        height: 200px;
                        margin: 0 10px 10px 0;
                    }
                }
    
                img {
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                    border: 1px solid #DDD;
                    border-radius: 4px;
                    background: #EEE;
                }
            }

            .netflix-ads {
                width: 25%;
                height: 250px;
                margin-left: 10px;
                border-radius: 4px;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .event-list-view {
            .event-list {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                .event-item {
                    width: 30%;
                    height: 250px;
                    position: relative;
                    margin-bottom: 40px;
                    border-radius: 8px;
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                    }

                    .action-pane {
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        z-index: 999;
                        padding: 10px 20px 20px 20px;
                        background: #000A;
                        color: white;
                        cursor: pointer;

                        .event-title {
                            font-size: 18px;
                            text-align: left;
                        }

                        .event-price {
                            background: white;
                            border-radius: 4px;
                            color: #eb2732;
                            font-weight: bold;
                            font-size: 11px;
                            height: 18px;
                            padding: 0 10px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-right: 10px;
                        }

                        .event-time {
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            span {
                                font-size: 4px;
                                margin: 0 5px;
                            }
                        }

                        .book-button {
                            display: none;
                            margin-top: 10px;
                            font-weight: bold;
                        }

                        &:hover {
                            .book-button {
                                display: block;
                            }
                        }
                    }
                }
            }
        }

    }

}