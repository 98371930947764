.App-Content
{

    .main-content {
        position: relative;
        height: calc(100% - 72px);
        top: 72px;
        bottom: 0;
        left: 0;
        right: 0;
        background: white;

        @media only screen and (max-width: 750px) {
            top: 50px;
            height: calc(100% - 50px);
            z-index: 99;
        }
    }
    
    .loading-container {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 9999;
        background: rgba(255, 255, 255, 0.7);
        padding: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}